<template>
  <div class="students">
    <div class="container">
      <h1 class="page-title">Students</h1>
      <div class="academic-year--filters"></div>
      <div class="academic-year--list" v-if="students.length > 0">
        <div
          class="academic-year--item"
          v-for="(year, index) of yearsWithStudents"
          :key="index"
        >
          <div class="academic-year--edition">
            <img src="@/assets/icon-student-grey.svg" width="16px" alt>
            <div class="edition green">{{year.edition}}' edition</div>
            <div class="year">{{ year.attributes.name }}</div>
          </div>
          <div class="academic-year--students">
            <router-link class="academic-year--student" v-for="(person, index) of year.students" :key="index" :to="studentLink(person)">
              {{personName(person)}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { slugify } from '@/libs/utils'

export default {
  components: {},
  data() {
    return {
      academicYears: [],
      students: []
    };
  },
  computed: {
    yearsWithStudents() {  
      if (this.students.length > 0) {
        const firstYear = 2014;
        return this.academicYears.map((year) => {
          year.students = this.getPeopleByYear(year);

          //calcolo numero edizione

          year.edition = (parseInt(year.attributes.name.split('/')[0]) + 1) - firstYear
          return year;
        }).filter(y=>{
          return y.edition > 0 && y.students.length > 0
        }).sort((a,b)=>{
          return b.edition - a.edition
        });
      } else {
        return []
      }
    },
  },
  methods: {
    getPeopleByYear(year) {
      return this.students.filter((student) => {
        if (student.relationships.field_academic_year.data.length > 0) {
          return (
            student.relationships.field_academic_year.data[0].id === year.id
          );
        }
      });
    },
    fetchAcademicYears() {
      return fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/taxonomy_term/academic_year/`)
        .then((res) => res.json())
        .then((response) => {
          this.academicYears = response.data;
      });
    },
    fetchPeople(toMerge, nextUrl) {
      let url = `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/person?filter[field_person_role]=student`
      if(nextUrl){
        url = nextUrl;
      } 
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          let students = json.data.filter((person) => {
            return person.attributes.field_person_role.includes("student");
          });
          
          if (toMerge !== undefined) {
            students = students.concat(toMerge)
          }

          if (json.links.next) {
            this.fetchPeople(students, json.links.next.href)
          } else {
            this.students = students;
          }
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    },
    studentLink(person){
      let personSlug = slugify(this.personName(person));
      return `/students/${personSlug}-${person.attributes.drupal_internal__nid}`
    },
    personName(person) {
      return person.attributes.field_first_name + " " + person.attributes.field_person_last_name;
    },
  },
  mounted() {
    this.fetchAcademicYears().then(()=>{
      this.fetchPeople();

    });
  },
};
</script>
<style lang="scss" scoped>
.academic-year--item {
  margin-bottom: 4rem;
}
.students{
  overflow: hidden;
}
.academic-year--edition{
  display: inline-block;
  position: relative;
  padding: 30px 90px 30px 66px;
  z-index: 1;
  &:before{
    display: block;
    position: absolute;
    top: 0;
    left: -200%;
    width: 300%;
    height: 100%;
    z-index: 9;
    content:"";
    border-top: 1px solid #1DC07E;
    border-bottom: 1px solid #1DC07E;
    border-right: 1px solid #1DC07E;
    padding: 30px 90px;
    border-radius: 0px 182px 182px 0px;
  }
  .edition{
    display: flex;
    color:#404259;
    text-transform: uppercase;
    font-size: 20px;
    img{
      margin-right: 5px;
    }
  }
  .year{
    color:#292D34;
    font-size: 1.875rem;
    font-weight: 200;
    font-family: Ubuntu;
  }
}
.academic-year--students{
    display: flex;
    flex-wrap: wrap;
    background-color: #F8F8F8;
    padding: 100px 20px 50px 320px;
    margin-top: -130px;
    margin-bottom: 20px;
    border-radius: 546px 0px 0px 532px;
    position: relative;
        z-index: 0;
  &:after{
    display: block;
    position: absolute;
    top: 0;
    right: -200%;
    width: 300%;
    height: 100%;
    z-index: -1;
    content:"";
    background-color: #F8F8F8;
    padding: 30px 90px;
    border-radius: 532px 0px 0px 532px;
  }
  .academic-year--student{
    padding: 20px 29px;
    position: relative;
    width: 33.3333%;
    color: #666666;
    &:after{
      content: "";
      background: #1DC07E;
      width: 80px;
      height: 2px;
      position: absolute;
      bottom: 1px;
      left: 29px;
    }
  }
}

@media (max-width:1200px) {
  .academic-year--students .academic-year--student[data-v-33dfcbd2]:after {
    left: 15px;
    bottom: 5px;
}
  .academic-year--students {
    padding: 148px 20px 39px 140px;
    }
  .academic-year--students .academic-year--student {
    padding: 11px 16px;
    height: 59px;
    display: flex;
  }
}
@media (max-width:768px) {
  .academic-year--students .academic-year--student[data-v-33dfcbd2]:after {
    left: 0px;
  }
  .academic-year--students .academic-year--student {
    padding: 15px 0px;
  }

  .academic-year--students {
    padding: 100px 0px 39px 0px;
    margin-top: -66px;
  }
  .academic-year--students:after {
    right: -100%;
    width: 300%;
  }
  .academic-year--edition {
    padding: 11px 90px 11px 0px;
  }
  .academic-year--students .academic-year--student {
    padding: 11px 16px 11px 0px;
    
  }

}
@media (max-width:576px) {
  .academic-year--students {
    padding: 21px 0px 20px 0px;
  }

  .academic-year--item {
    margin-bottom: 3rem;
  }
  .academic-year--students {
    padding: 40px 0px 20px 0px;
    margin-top: 0;
  }
  .academic-year--edition {
      margin-bottom: 1rem;
  }
  .academic-year--students .academic-year--student {
    padding: 0;
    width: 100%;
    padding: 13px 0px;
    height: auto;
  }
}
</style>